@font-face{
font-family:"NYIrvin";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/nyirvin_3-web.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/nyirvin_3-web.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/nyirvin_3-web.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/nyirvin_3-web.ttf?8103515130907419127') format("truetype");
}
@font-face{
font-family:"Avenir LT W01 95 Black";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/5a05cf7f-f332-456f-ae67-d8c26e0d35b3.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/c33278bc-988e-440b-b4be-6acc095e6044.ttf?8103515130907419127') format("truetype"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/7bdad4a5-289b-42f9-b6fa-c5883b9e9f23.svg%237bdad4a5-289b-42f9-b6fa-c5883b9e9f23?8103515130907419127') format("svg");
}

@font-face{
font-family:"Avenir LT W01 85 Heavy";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf?8103515130907419127') format("truetype"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg%23731dd4d3-64da-427d-ba61-01575b3cf3f7?8103515130907419127') format("svg");
}

@font-face{
font-family:"JansonTextW04-55Roman";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/467e6cf5-f6b0-4238-93e6-d6d78f787167.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/467e6cf5-f6b0-4238-93e6-d6d78f787167.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/44161ea4-4280-4fe5-92aa-697b958265f2.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/04de951f-a57e-4c67-9f05-56c1e471ddc0.ttf?8103515130907419127') format("truetype"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/88b88383-63ea-4e71-bbc5-1191ccd5323d.svg%2388b88383-63ea-4e71-bbc5-1191ccd5323d?8103515130907419127') format("svg");
}

@font-face{
font-family:"Janson Text W01SC 55 Rm SC";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/ceb88df1-5a1c-45ff-8fda-7086ed5e03dd.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/ceb88df1-5a1c-45ff-8fda-7086ed5e03dd.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/b073bf7f-04d4-4a15-810d-fca900899d75.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/43a1ca5b-f7ed-4f68-b9cd-54cb5eaf7cf0.ttf?8103515130907419127') format("truetype"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/578a6d00-ace3-44b7-a99c-c600b4ce6bed.svg%23578a6d00-ace3-44b7-a99c-c600b4ce6bed?8103515130907419127') format("svg");
}
@font-face{
font-family:"JansonTextW01-56Italic";
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/fd0cfd53-00d2-4f4c-94f8-f880927b634d.eot?%23iefix&8103515130907419127');
src:url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/fd0cfd53-00d2-4f4c-94f8-f880927b634d.eot?%23iefix&8103515130907419127') format("eot"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/f15b0119-d12d-4a9c-ba3f-54ffb2165679.woff?8103515130907419127') format("woff"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/c635a795-1ecc-4da9-be84-7fe69737fb5c.ttf?8103515130907419127') format("truetype"),
url('//cdn.shopify.com/s/files/1/0368/5017/t/7/assets/35a6a1ce-797d-47f5-92d9-e4bd3cea1c60.svg%2335a6a1ce-797d-47f5-92d9-e4bd3cea1c60?8103515130907419127') format("svg");
}

.lookup-page-container {
  font-family: 'JansonTextW04-55Roman', Georgia, Utopia, 'Times New Roman', Times, serif;
  background: #212121 !important;
}

.lookup-page-container h1,
.lookup-page-container h2,
.lookup-page-container h3,
.lookup-page-container h4,
.lookup-page-container h5,
.lookup-page-container h6
{ font-family: 'Avenir LT W01 95 Black'; }

#pageheader .logotext
{ font-family: 'Avenir LT W01 95 Black'; }

/*Batch Notes*/

  .lookup-block {
    display: inline-block;
    /*margin-right: 5%;*/
    margin-right: 0;
    vertical-align: top;
    text-align: center;
  }

  .lookup-block-results{
    width: 100%;
    text-align: center;
    /*padding: 30px;*/
    padding: 30px 0;
    border-top:1px solid #4d4d4d;
    float: right;
    margin-top: 45px;
  }

  .lookup-block-right {
    float:right;
    width:48%;
  }
  .lookup-search{text-align: center;}
  @media screen and (max-width: 875px) {
    .lookup-block-right {
      float: none;
    }
  }

 .lookup-block-left {
  float:left;
  /*width:375px;*/
  width:45%;
  }

  @media screen and (max-width: 875px) {
    .lookup-block-left {
      float: none;
      margin: 0;
      width: auto;
    }
    .lookup-block-right {
      margin: 1em 0 0 0;
      width: 100%;
    }
  }
  .lookup-block img{
    margin: 36px auto;
  }

  .lookup-block h1 {
    font-family: 'NYIrvin';
    font-size: 27px;
    color: #fffcdf;
    font-weight: normal;
    text-align: center;
    margin: 0;
    -webkit-margin-before: 0.67em;
    -webkit-margin-after: 0.67em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  }
   .lookup-block h2 {
    font-family: 'NYIrvin';
   font-size: 20px;
   color: #fffcdf;
   font-weight: normal;
   letter-spacing: 1px;
   text-align: center;
   margin: 0;
   -webkit-margin-before: 0.67em;
   -webkit-margin-after: 0.67em;
   -webkit-margin-start: 0px;
   -webkit-margin-end: 0px;
  }
  .lookup-block p {
    color: #a7a79c;
    font-size: 16px;
    text-align: left;
  }
  .lookup-block-right h3 {
   font-family: 'Janson Text W01SC 55 Rm SC';
   font-size: 18px;
   color: #fffcdf;
   font-weight: normal;
   text-align: center;
   margin: 12px 0 0;
}

  .lookup-intro h4 {
    color: #828280;
    font-family:"Avenir LT W01 85 Heavy";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.5;
    text-align: left;
    font-weight: normal;
    margin: 0 0 0.25em;
  }
  .lookup-intro p {
    font-family: 'JansonTextW04-55Roman', Georgia, Utopia, 'Times New Roman', Times, serif;
    color: #828280;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.5;
  }


.results .lookup-block {
  color: #828280;
  text-align: left;
}
.results .lookup-block h2 {
  font-family: 'Avenir LT W01 95 Black';
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

.results .lookup-block h3 {
  color: #5f554d;
  /*font-family:"Avenir LT W01 85 Heavy";*/
  font-family:"Janson Text W01SC 55 Rm SC";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0.75em 0 0.5em;
  text-align: left;
  text-transform: lowercase;
}
.results p {
    color: #828280;
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0.5em;
}
.results ul {
  list-style-type: none;
  padding: 0;
}
.results li {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin: 0 0 0.75em;
}
.results li .ingred-name {
  color: #F89D1E;
  font-weight: normal;
}

.batch-form-cont {
  /*text-align: center;*/
  clear: both;
  padding: 0;
  white-space: nowrap;
}

.batch-form {
  zoom:1;
  display:inline-block;
  vertical-align: middle;
  padding: 0 0 36px 10px;
  //background-image: url(), url();
  background-position: left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 100%, auto 100%;
  /* border-bottom: 3px solid #212121; */
  padding: 36px 12px 36px;
  /*width: 95%;*/
  /*width: 100%;*/
  width: auto;
  /*max-width: 300px;*/
  max-width: 425px;
  margin: 0;
}

.batch-form .batch-lookup-form{
  border: 0;
  border-top: 2px solid #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
  background-color: transparent;
}

.batch-form label {
  color: #fff5ce;
  font-family:"Avenir LT W01 85 Heavy";
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 0 20px;
  border-right: 1px solid #4d4d4d;
  vertical-align: middle;
  text-transform: uppercase;
}

.batch-form input[type="text"] {
  background: transparent;
  border: none;
  color: #828280;
  font-family:"JansonTextW04-55Roman";
  text-align: center;
  font-size: 16px;
  width: 49%;
}
.lookup-button {
  background: transparent;
  //background-image: url();
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  color: #FFFBD5;
  cursor: pointer;
  display:inline-block;
  margin: 10px 10px 10px 0;
  /*font-size: 16px;*/
  /*padding: 16px 12px 16px;*/
  height: 27px;
  overflow:hidden;
  text-indent:-9000px;
  width: 27px;
}
.batch-form h3 {
  color: #FFFBD5;
  font-family:"JansonTextW01-56Italic";
  font-size: 18px;
  font-style: normal;
  line-height: 1;
  margin: 0 0 1em;
}

.batch-form h4 {
  margin: 0.6em 2em;
}
.user-content #origin-info h2 {
  display: inline-block;
  font-size: 20px;
  margin: 0 0 1.5em;
  text-transform: uppercase;
}
.batch-product-image {
  float: right;
  margin: -1em -1em 0 0;
  max-width: 45%;
}

.lookup-block table {
  margin-bottom: 0.75em;
}

.lookup-block td {
  border: none;
  color: #a7a79c;
  display: block;
  font-size: 16px;
  padding: 0;
}
.lookup-block .label {
  color: #645951;
  font-family:"JansonTextW04-55Roman";
  letter-spacing: 1px;
  text-transform: lowercase;
}
#origin-ingredients tr {
  display: block;
  padding: 0 0 0.5em;
}
.ingred-name {
  color: #f89d1e;
}
