body {
  font-family: $font-family-sans-serif;
  font-size: 16px;
}

p {
  font-family: $font-family-sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $font-family-serif;
  font-weight: 700;
}

.panel-heading {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 20px 15px;
}

.group-heading {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 18px;
}

.no-dec {
  list-style: none !important;
}

.delete-present-blend {
  cursor: pointer;
}

.app-logo {
  height: 50px;
  padding-right: 20px;

}

.navbar-brand {
  padding: 0 15px 14px 15px !important;
}

//Padding Helpers

.pr-3 {
  padding-right: 10px;
}

.pl-3 {
  padding-left: 10px;
}

.pt-3 {
  padding-top: 10px;
}

.pb-3 {
  padding-bottom: 10px;
}

.mr-3 {
  margin-right: 10px;
}

.ml-3 {
  margin-left: 10px;
}

.mt-3 {
  margin-top: 10px;
}

.mb-3 {
  margin-bottom: 10px;
}


// height and width helpers

.full-width {
  width: 100%;
}


//form helpers

.unlink,
.unlink:hover,
.unlink:active,
.unlink:focus {
  text-decoration: none;
}

// Table helpers

.thead-inverse {
  background: #545453;
  color: #fff;
}


//Complaints show formatting

.complaint-data-label {
  display: inline-block;
  width: 120px;
  text-transform: uppercase;
  font-family: $font-family-serif;
  font-size: 12px;
}

.complaint-data-note {
  display: block;
  width: 120px;
  text-transform: uppercase;
  font-family: $font-family-serif;
  font-size: 12px;
  padding-bottom: 15px;
}

.pad-data-label {
  padding-left: 100px;
}


//Actions card

.card {
  border: 1px solid #ddd;
  padding: 10px 15px;
  margin: 10px;
  background: #FCFBF8;
}
.card-header {
  height: 40px;
  font-family: $font-family-serif;
  font-size: 16px;
  padding-bottom: 5px;
  cursor: pointer;
}
.card-body {
  padding: 15px 10px;
  font-family: $font-family-sans-serif;
  border-top: 1px solid #ddd;
}
.card-footer {
  padding-top: 15px;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-family: $font-family-sans-serif;
}

.hideable {
  display: none;
}


//combobox
.source {
  display: none;
}
