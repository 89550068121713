.no-dec {
  list-style: none !important;
}

.delete-present-blend,
.delete-present-workcenter,
.delete-present-sku {
  cursor: pointer;
}


.workcenter-selector,
.sku-selector,
.type-selector {
  display: none;
}

.selector-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select2-selection__choice {
  width: 100%;
}

.selection {
  width: 100%;
}

.blend-btn-grp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
